import { CartInputInterface } from "../interfaces/Shopify/CartInputInterface";
import { CartInterface } from "../interfaces/Shopify/CartInterface";
import { CartLineUpdateInputInterface } from "../interfaces/Shopify/CartLineUpdateInputInterface";
import { ProductInterface } from "../interfaces/Shopify/ProductInterface";
import { AsyncRequest } from "./asyncrequest";


export class Store {
    GetProduct(id: string) {
        AsyncRequest<ProductInterface>("/api/store/product?id=" + id, {
            method: "get"
        }).then((response: ProductInterface) => {
            console.log(response);
        })
    };

    CreateCart(cartInput: CartInputInterface) {
        let data: FormData = new FormData();
        data.append("cartInput", JSON.stringify(cartInput));

        AsyncRequest<CartInterface>("/api/store/cart/create", {
            method: "post",
            body: data
        }).then((response: CartInterface) => {
            console.log("Create cart:");
            console.log(response);
        })
    }

    GetCart(cartId: string) {
        AsyncRequest<CartInterface>("/api/store/cart?id=" + cartId, {
            method: "get"
        }).then((response: CartInterface) => {
            console.log(response);
        })
    };

    UpdateCart(cartId: string, cartLines: CartLineUpdateInputInterface[]) {
        let data: FormData = new FormData();
        data.append("cartId", cartId);
        data.append("cartLines", JSON.stringify(cartLines));

        AsyncRequest<CartInterface>("/api/store/cart/update", {
            method: "post",
            body: data
        }).then((response: CartInterface) => {
            console.log(response);
        })
    };
}