/// <reference types="@types/googlemaps" />

export default class DapthMap {
    private infowindow: google.maps.InfoWindow | null = null;
    private map: google.maps.Map | undefined;
    private locationItems: HTMLElement | null = document.querySelector('.location-scroller');

    constructor(mapID: string, zoom: number, lat: number, long: number) {

        const mapProp: google.maps.MapOptions = {
            center: new google.maps.LatLng(lat, long),
            zoom: zoom,
        };

        if (typeof document.getElementById(`${mapID}`) !== 'undefined' && document.getElementById(`${mapID}`) !== null) {
            this.map = new google.maps.Map(document.getElementById(`${mapID}`) as HTMLElement, mapProp);

            if (this.locationItems !== null) {
                for (let i = 0; i < this.locationItems.children.length; i++) {
                    this.CreateMarker(this.locationItems.children[i].getAttribute('data-locationname') as string);
                    this.AddOnClick(this.locationItems.children[i] as HTMLElement);
                }
            }

            const getPosition: PositionOptions = {
                enableHighAccuracy: false,
                timeout: 9000,
                maximumAge: 0,
            };

            const success = (gotPosition: any): void => {
                const uLat: number = gotPosition.coords.latitude;
                const uLon: number = gotPosition.coords.longitude;

                // Create Marker
                const marker: google.maps.Marker = new google.maps.Marker({
                    position: { lat: uLat, lng: uLon },
                    map: this.map,
                    title: 'Your Location',
                });

                marker.addListener('click', () => {
                    // Close existing info windows
                    if (this.infowindow) {
                        this.infowindow.close();
                    }

                    const location: google.maps.LatLngLiteral = { lat: uLat, lng: uLon };

                    if (this.map) {
                        this.map.setZoom(17);
                        this.map.panTo(location);
                        this.checkBounds();
                    }

                    this.infowindow = new google.maps.InfoWindow({
                        content: "<b style='font-weight: 700'>" + 'Your Location' + '</b><br>',
                    });

                    // Open infowindow
                    if (this.map && marker) {
                        this.infowindow.setPosition(marker.getPosition() || null);
                        this.infowindow.open(this.map, marker);
                    }
                });
            };

            const error = (): void => {
                console.warn('Google Maps - Unable to grab user location');
            };

            navigator.geolocation.getCurrentPosition(success, error, getPosition);

            const Dapth: google.maps.MapTypeStyle[] = [
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#9fa8a5',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#373a3d',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#606f69',
                        },
                    ],
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#606f69',
                        },
                    ],
                },
            ];

            if (this.map) {
                this.map.setOptions({ styles: Dapth });
            }
        }

        const searchForm = document.getElementById('mapsearchForm') as HTMLFormElement | null;

        if (searchForm) {
            searchForm.addEventListener('submit', (event) => {
                event.preventDefault();

                const postcodeInput = document.getElementById('sortpostcode') as HTMLInputElement | null;

                if (postcodeInput) {
                    const postcode = postcodeInput.value;

                    this.SortPostcode(postcode)

                }
            })
        }
    }

    AddOnClick(element: any) {

        element.addEventListener("click", () => {
            this.map.setZoom(17);
            var lat = parseFloat(element.getAttribute('data-lat'));
            var lng = parseFloat(element.getAttribute('data-lng'));
            let loc = { lat: lat, lng: lng };
            this.map.panTo(loc);
        });

    }

    CreateMarker(elementName: any) {

        let locationItem = document.querySelector('[data-locationname="' + elementName + '"]');
        let locationName = locationItem.getAttribute("data-locationname");
        let lat = parseFloat(locationItem.getAttribute("data-lat"));
        let lng = parseFloat(locationItem.getAttribute('data-lng'));
        let contentString = "<b style='font-weight: 700'>" + locationItem.getAttribute("data-locationname") + "</b><br>" + "<a href='https://www.google.com/maps?q=" + encodeURIComponent(locationItem.getAttribute("data-address")) + "' target='_blank'>" + locationItem.getAttribute("data-address") + "</a>";
        let location = { lat: lat, lng: lng };

        //Create Marker
        let marker = new google.maps.Marker({
            position: location,
            map: this.map,
            title: locationName,
        });

        marker.addListener("click", () => {
            //Close existing info windows
            if (this.infowindow) {
                this.infowindow.close();
            }

            this.map.setZoom(17);
            this.map.panTo(location);
            this.checkBounds();

            this.infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            //Open infowindow
            this.infowindow.setPosition(marker.getPosition() || null);
            this.infowindow.open(this.map, marker);
        });

        //Events
        this.map.addListener("mousedown", () => {
            this.checkBounds();
        });

        this.map.addListener("zoom_changed", () => {
            this.checkBounds();
        });

        this.map.addListener("dragend", () => {
            this.checkBounds();
        });

        this.map.addListener("idle", () => {
            this.checkBounds();
        });
    }

    checkBounds() {
        const locationCont = document.querySelector('.location-scroller');
        let itemsToSort = [];

        const centerMapLat = this.map.getCenter().lat();
        const centerMapLong = this.map.getCenter().lng();
        const centerMap: any = [centerMapLong, centerMapLat]

        for (let i = 0; i < this.locationItems.children.length; i++) {
            const fromLatString = this.locationItems.children[i].getAttribute("data-lat");
            const fromLat: number = parseFloat(fromLatString);
            const fromLngString = this.locationItems.children[i].getAttribute("data-lng");
            const fromLng: number = parseFloat(fromLngString);

            const from: any = [fromLng, fromLat]; // Turf.js expects [lng, lat]
            var options: any = { units: 'kilometers' };
            const distance: any = turf.distance(centerMap, from, options);
            this.locationItems.children[i].setAttribute("data-distance", distance);

        }

        if (this.locationItems != null) {
            for (let i = 0; i < this.locationItems.children.length; i++) {
                itemsToSort.push(this.locationItems.children[i].getAttribute("data-distance").toString());
                const childElement = this.locationItems?.children[i] as HTMLElement | undefined;

                if (childElement) {
                    childElement.style.display = 'none';
                }
            }
        }

        // Sort the items by distance (furthest to nearest)
        itemsToSort.sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
        });

        for (let o = 0; o < itemsToSort.length; o++) {
            let mapLocation = document.querySelector('[data-distance="' + itemsToSort[o] + '"]') as HTMLElement | undefined;

            if (mapLocation != null) {
                mapLocation.style.display = "block";
                locationCont.appendChild(mapLocation);
            }
        }
    }

    SortPostcode(postcode: any) {
        event.preventDefault();

        let fetchLocation = "https://maps.google.com/maps/api/geocode/json?components=country:AU|postal_code:" + postcode + "&sensor=false&key=AIzaSyDWK_xcF1tUT7DUIRaQ_KYTD219NxPW5TE"

        if (postcode == '') {
            alert("Please enter a postcode");
            return;
        }

        const isPostcode: number = postcode.split("").length;

        if (isPostcode != 4) {
            const address = postcode.replaceAll(" ", "+");
            console.log(address)
            fetchLocation = "https://maps.google.com/maps/api/geocode/json?address=" + address + "&sensor=false&key=AIzaSyDWK_xcF1tUT7DUIRaQ_KYTD219NxPW5TE";
            console.log(fetchLocation)
        }

        fetch(fetchLocation)
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch data');
                }
            })
            .then((data) => {

                if (data == null) return;

                if (data['results'] == null) return;

                if (data['results'][0] == null) return;


                let lat = data['results'][0].geometry.location.lng;
                let lng = data['results'][0].geometry.location.lat;

                if (lat != null && lng != null) {

                    if (this.locationItems != null) {

                        for (let i = 0; i < this.locationItems.children.length; i++) {
                            let fromLatString = this.locationItems.children[i].getAttribute("data-lat");
                            let fromLat: number | null = parseFloat(fromLatString);
                            let fromLngString = this.locationItems.children[i].getAttribute("data-lng");
                            let fromLng: number | null = parseFloat(fromLngString);

                            var options: any = {
                                units: 'kilometers'
                            };

                            var to: any = [lng, lat] //lng, lat
                            var from: any = [fromLat, fromLng] //lng, lat
                            var distance: any = turf.distance(to, from, options);

                            this.locationItems.children[i].setAttribute("data-distance", distance);
                        }
                    }
                }

                let attributeDistances = [];
                let locationCont = document.querySelector('.location-scroller')

                if (this.locationItems != null) {
                    for (let i = 0; i < this.locationItems.children.length; i++) {
                        attributeDistances.push(this.locationItems.children[i].getAttribute("data-distance").toString());
                        const childElement = this.locationItems?.children[i] as HTMLElement | undefined;

                        if (childElement) {
                            childElement.style.display = 'none';
                        }
                    }
                }
                attributeDistances.sort(function (a, b) {
                    return parseFloat(a) - parseFloat(b);
                });

                for (let o = 0; o < attributeDistances.length; o++) {
                    let mapLocation = document.querySelector('[data-distance="' + attributeDistances[o] + '"]') as HTMLElement | undefined;

                    if (mapLocation != null) {
                        mapLocation.style.display = "block";
                        locationCont.appendChild(mapLocation);
                    }
                }

                let zoomLocation = { lat: lng, lng: lat };
                this.map.setZoom(13);
                this.map.panTo(zoomLocation);
                this.checkBounds();
            });

        event.preventDefault();
    }
}
