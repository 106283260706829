//Components
import { Store } from "./components/store"
import DapthMap from "./components/dapth-map"
import SortBlogs from "./components/sort-blogs"

class CoreSite {
    DapthMap: DapthMap;
    SortBlogs: SortBlogs;

    constructor() {
    }
}

export const Core: CoreSite = new CoreSite();
export { Store };
export { DapthMap };
export { SortBlogs };
