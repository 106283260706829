export async function AsyncRequest<T>(
    url: string,
    config: RequestInit = {}
): Promise<T> {
    try {
        const response = await fetch(url, config);
        return await response.json();
    }
    catch (error) {
        return error;
    }
}