export default class SortBlogs {
    constructor() {
        // Get references to the necessary elements
        const sortSelector = document.getElementById('sort-blog-posts') as HTMLSelectElement;
        const blogPostsContainer = document.querySelector('.blog-listing-component .component-main.listing_basic-grid') as HTMLElement;

        // Function to sort and re-render the blog posts based on the selected option
        function sortBlogPosts(sortOption: string) {
            const blogPosts = Array.from(blogPostsContainer.querySelectorAll('.item-blog') as NodeListOf<HTMLElement>);

            switch (sortOption) {
                case 'newest-to-oldest':
                    blogPosts.sort((a, b) => {
                        const dateA = new Date(a.getAttribute('data-postdate') || '');
                        const dateB = new Date(b.getAttribute('data-postdate') || '');
                        return dateB.getTime() - dateA.getTime();
                    });
                    break;
                case 'oldest-to-newest':
                    blogPosts.sort((a, b) => {
                        const dateA = new Date(a.getAttribute('data-postdate') || '');
                        const dateB = new Date(b.getAttribute('data-postdate') || '');
                        return dateA.getTime() - dateB.getTime();
                    });
                    break;
                case 'a-to-z':
                    blogPosts.sort((a, b) => {
                        const titleA = (a.getAttribute('data-title') || '').toUpperCase();
                        const titleB = (b.getAttribute('data-title') || '').toUpperCase();
                        console.log("test3")

                        return titleA.localeCompare(titleB);
                    });
                    break;
                case 'z-to-a':
                    blogPosts.sort((a, b) => {
                        const titleA = (a.getAttribute('data-title') || '').toUpperCase();
                        const titleB = (b.getAttribute('data-title') || '').toUpperCase();
                        console.log("test4")

                        return titleB.localeCompare(titleA);
                    });
                    break;
            }

            // Append sorted blog posts back to the container
            blogPosts.forEach(post => {
                blogPostsContainer.appendChild(post);
            });
        }

        // Initial sorting based on the default option
        sortBlogPosts(sortSelector.value);

        // Event listener for the dropdown change
        sortSelector.addEventListener('change', function () {
            sortBlogPosts(this.value);
        });
    }
}
